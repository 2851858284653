<template>
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
      <div class="card">
        <div class="card-header">
          <div class="row">
            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
              <!-- <a-button
                id="buttontambahdiindex"
                class="btn btn-outline-primary btn-sm"
                @click="sendToForm('create')"
                >Tambah</a-button
              >
              <a
                class="text-success ml-2"
                @click="getExDocument"
                >Download contoh template</a
              >
              <a-divider type="vertical"/>
              <a
                class="text-primary"
                @click="showUpload()"
                >Upload template baru</a
              >
              <input type="file" accept=".docx" id="file" ref="file" class="d-none" v-on:change="handleFileUpload()"/> -->
              <strong>List Pengajuan</strong>
            </div>
            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
              <a-month-picker :default-value="moment()" format="MMMM YYYY" class="float-right" @change="selectDate" />
            </div>
          </div>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <a-table
                :bordered="true"
                :columns="columns"
                :data-source="datatable"
                size="small"
                style="
                  margin-left: -26px;
                  margin-right: -26px;
                  margin-top: -21px;
                "
                :customRow="customRow"
                :loading="loading"
                :pagination="{
                  hideOnSinglePage: true,
                  defaultPageSize: 10,
                  showQuickJumper: true,
                  showSizeChanger: true,
                  showTotal: (total) => `Total ${total} items`,
                  pageSizeOptions: ['10', '20', '30'],
                }"
              >
                <div
                  slot="filterDropdown"
                  slot-scope="{
                    setSelectedKeys,
                    selectedKeys,
                    confirm,
                    clearFilters,
                    column,
                  }"
                  style="padding: 8px;"
                >
                  <a-input
                    v-ant-ref="(c) => (searchInput = c)"
                    :placeholder="`Search ${column.title}`"
                    :value="selectedKeys[0]"
                    style="width: 188px; margin-bottom: 8px; display: block;"
                    @change="
                      (e) =>
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                    "
                    @pressEnter="
                      () =>
                        handleSearch(selectedKeys, confirm, column.dataIndex)
                    "
                  />
                  <a-button
                    type="primary"
                    icon="search"
                    size="small"
                    style="width: 90px; margin-right: 8px;"
                    @click="
                      () =>
                        handleSearch(selectedKeys, confirm, column.dataIndex)
                    "
                  >
                    Search
                  </a-button>
                  <a-button
                    size="small"
                    style="width: 90px;"
                    @click="() => handleReset(clearFilters)"
                  >
                    Reset
                  </a-button>
                </div>
                <a-icon
                  slot="filterIcon"
                  slot-scope="filtered"
                  type="search"
                  :style="{ color: filtered ? '#108ee9' : undefined }"
                />
                <template
                  slot="customRender"
                  slot-scope="text, record, index, column"
                >
                  <span
                    v-if="searchText && searchedColumn === column.dataIndex"
                  >
                    <template
                      v-for="(fragment, i) in text
                        .toString()
                        .split(
                          new RegExp(
                            `(?<=${searchText})|(?=${searchText})`,
                            'i'
                          )
                        )"
                    >
                      <mark
                        v-if="
                          fragment.toLowerCase() === searchText.toLowerCase()
                        "
                        :key="i"
                        class="highlight"
                        >{{ fragment }}</mark
                      >
                      <template v-else>{{ column.dataIndex === 'tglregister' ? moment(fragment, 'YYYY-MM-DD').format('DD-MM-YYYY') : fragment }}</template>
                    </template>
                  </span>
                  <template v-else>
                    <span v-if="column.dataIndex === 'tglregister'">{{ moment(text, 'YYYY-MM-DD').format('DD-MM-YYYY') }}</span>
                    <span v-else> {{ text }}</span>
                  </template>
                </template>
                <template slot="operations" slot-scope="text, record">
                  <a-icon
                    type="edit"
                    class="text-warning"
                    @click="sendToForm('update', record)"
                  />
                  <a-divider type="vertical" />
                  <a-icon
                    type="delete"
                    class="text-danger"
                    @click="showDeleteConfirm(record)"
                  />
                  <a-divider type="vertical" />
                  <a-icon
                    type="printer"
                    class="text-success"
                    @click="getReciept(record)"
                  />
                </template>
                <template slot="dropdown" slot-scope="text, record">
                  <a-dropdown :trigger="['contextmenu']">
                    <!-- <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12"> -->
                    <span> {{ text }}</span>
                    <!-- </div> -->
                    <a-menu slot="overlay">
                      <a-menu-item>
                        <a-icon
                          type="edit"
                          class="text-warning"
                          @click="sendToForm()"
                        />
                        <span class="ml-1 text-warning">Edit</span>
                      </a-menu-item>
                      <a-menu-item>
                        <a-icon
                          type="delete"
                          class="text-danger"
                          @click="showDeleteConfirm(record)"
                        />
                        <span class="ml-1 text-danger">Hapus</span>
                      </a-menu-item>
                      <a-menu-item>
                        <a-icon
                          type="printer"
                          class="text-success"
                          @click="getReciept(record)"
                        />
                        <span class="ml-1 text-success">Print</span>
                      </a-menu-item>
                    </a-menu>
                  </a-dropdown>
                </template>
                <template slot="jaminan" slot-scope="text, record">
                  <a-dropdown :trigger="['click']">
                    <!-- <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12"> -->
                    <a-tooltip>
                      <template slot="title">
                        Tambahkan jaminan?
                      </template>
                      <a-tag color="blue" style="cursor: pointer;">
                        <a-icon type="plus" />
                      </a-tag>
                    </a-tooltip>
                    <!-- </div> -->
                    <a-menu slot="overlay">
                      <a-menu-item @click="sendToForm('bpkb', record, 'create')">
                        <a-icon
                          type="plus"
                          class="text-primary"
                        />
                        <span class="ml-1">BPKB</span>
                      </a-menu-item>
                      <a-menu-item @click="sendToForm('shm', record, 'create')">
                        <a-icon
                          type="plus"
                          class="text-primary"
                        />
                        <span class="ml-1">SHM</span>
                      </a-menu-item>
                      <a-menu-item @click="sendToForm('deposito', record, 'create')">
                        <a-icon
                          type="plus"
                          class="text-primary"
                        />
                        <span class="ml-1">Deposito</span>
                      </a-menu-item>
                    </a-menu>
                  </a-dropdown>
                  <span v-for="(data, index) in record.list_jaminan" :key="index" @click="sendToForm(data.jenisjaminan.toLowerCase(), record, 'update', data)">
                    <a-dropdown :trigger="['contextmenu']" v-if="data.isdeleted === 0">
                      <!-- <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12"> -->
                      <a-tooltip>
                        <template slot="title">
                          <span v-if="data.jenisjaminan === 'Deposito'">Nominal</span><span v-else>Maksimal Plafond:</span> {{ numberFormat(data.jenisjaminan === 'Deposito' ? data.nominal : data.maksimalplafondkredit) }}
                        </template>
                        <a-tag :color="data.jenisjaminan === 'BPKB' ? 'green' : data.jenisjaminan === 'SHM' ? 'orange' : 'red'" style="cursor: pointer;">
                          {{data.jenisjaminan}}
                        </a-tag>
                      </a-tooltip>
                      <!-- </div> -->
                      <a-menu slot="overlay">
                        <a-menu-item @click="sendToForm(data.jenisjaminan.toLowerCase(), record, 'update', data)">
                          <a-icon
                            type="edit"
                            class="text-warning"
                          />
                          <span class="ml-1">Edit</span>
                        </a-menu-item>
                        <a-menu-item @click="showDeleteConfirm(data.jenisjaminan.toLowerCase(), data)">
                          <a-icon
                            type="delete"
                            class="text-danger"
                          />
                          <span class="ml-1">Delete</span>
                        </a-menu-item>
                      </a-menu>
                    </a-dropdown>
                  </span>
                </template>
                <span slot="nominal" slot-scope="text">
                  {{ numberFormat(text) }}
                </span>
                <span slot="analisis" slot-scope="text, record">
                  <a-button class="btn btn-outline-primary" @click="sendToForm('analisis', record)">Analisis</a-button>
                </span>
              </a-table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <detail ref="datadetail"> </detail> -->
  </div>
</template>

<script>
import * as lou from '@/services/data/lou'
import moment from 'moment'
// import detail from './detail'
import { Modal } from 'ant-design-vue'

export default {
  components: {
    // detail,
  },
  data() {
    return {
      tgl1: moment().startOf('month').format('YYYY-MM-DD'),
      tgl2: moment().endOf('month').format('YYYY-MM-DD'),
      columns: [
        {
          title: 'Actions',
          dataIndex: '#',
          width: 100,
          scopedSlots: {
            customRender: 'analisis',
          },
        },
        {
          title: 'Tanggal',
          dataIndex: 'tglregister',
          scopedSlots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
            customRender: 'customRender',
          },
          onFilter: (value, record) =>
            moment(record.tglregister, 'YYYY-MM-DD').format('DD-MM-YYYY').toString()
              .toLowerCase()
              .includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus()
              }, 0)
            }
          },
          sorter: (a, b) => moment(a.tglregister).unix() - moment(b.tglregister).unix(),
        },
        {
          title: 'No Pengajuan',
          dataIndex: 'kode',
          scopedSlots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
            customRender: 'customRender',
          },
          onFilter: (value, record) =>
            record.kode.toString().toLowerCase().includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus()
              }, 0)
            }
          },
          sorter: (a, b) => a.kode.length - b.kode.length,
        },
        {
          title: 'Nama',
          dataIndex: 'nama',
          ellipsis: true,
          scopedSlots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
            customRender: 'customRender',
          },
          onFilter: (value, record) =>
            record.nama.toString().toLowerCase().includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus()
              }, 0)
            }
          },
          sorter: (a, b) => a.nama.length - b.nama.length,
        },
        {
          title: 'Alamat',
          dataIndex: 'alamat',
          ellipsis: true,
          scopedSlots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
            customRender: 'customRender',
          },
          width: '30%',
          onFilter: (value, record) =>
            record.alamat.toString().toLowerCase().includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus()
              }, 0)
            }
          },
        },
        {
          title: 'Plafond Pengajuan',
          dataIndex: 'plafond',
          ellipsis: true,
          scopedSlots: {
            customRender: 'nominal',
          },
          sorter: (a, b) => a.plafond - b.plafond,
        },
        {
          title: 'Data Jaminan',
          dataIndex: 'jaminan',
          scopedSlots: {
            customRender: 'jaminan',
          },
          // sorter: (a, b) => a.list_jaminan.length - b.list_jaminan.length,
        },
      ],
      datatable: [],
      searchText: '',
      searchInput: null,
      searchedColumn: '',
      dropdownVisibility: false,
      loading: false,
    }
  },
  created() {
    this.getAllData()
  },
  methods: {
    moment,
    async getAllData() {
      var response = await lou.customUrlGet2('pengajuankredit?dari=' + this.tgl1 + '&sampai=' + this.tgl2)
      this.datatable = response.data
    },
    async getReciept(record) {
      await lou.getDocument('taksasibpkb', record.id)
    },
    async getExDocument() {
      await lou.getDocument('taksasibpkb', '')
    },
    showUpload() {
      this.$refs.file.click()
    },
    async handleFileUpload() {
      this.file = this.$refs.file.files[0]
      // console.log('this.$refs.file.files[0]', this.$refs.file.files[0])
      const formData = new FormData()
      formData.append('template', this.file)
      await lou.uploadDocument('taksasibpkb', formData)
    },
    sendToForm(jaminan, record, action = 'create', recordJaminan = {}) {
      // localStorage.removeItem('dataeditanalisis')
      localStorage.setItem('dataeditanalisis', JSON.stringify({ id: '' }))
      record.action = action
      record.target_jaminan = recordJaminan
      if (jaminan === 'bpkb') {
        localStorage.setItem('datanasabah', JSON.stringify(record))
        this.$router.push('/taksasijaminan/bpkb/form')
      } else if (jaminan === 'shm') {
        localStorage.setItem('datanasabah', JSON.stringify(record))
        this.$router.push('/taksasijaminan/shm/form')
      } else if (jaminan === 'deposito') {
        localStorage.setItem('datanasabah', JSON.stringify(record))
        this.$router.push('/taksasijaminan/deposito/form')
      } else if (jaminan === 'analisis') {
        localStorage.setItem('dataanalisis', JSON.stringify(record))
        this.$router.push('/taksasijaminan/analisamikro/form/ultramicro')
      } else {
        this.$router.push('/auth/404')
      }
    },
    showDeleteConfirm(to, deldata) {
      Modal.confirm({
        title: 'Yakin ingin menghapus data!?',
        content: 'Data akan hilang selamanya!!! ',
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'No',
        onOk: async () => {
          var res = await lou.customUrlDelete2('taksasi' + to, deldata.id_jaminan)
          if (res) {
            deldata.isdeleted = 1
            // console.log('deldata', deldata)
          }
        },
        onCancel: () => {
          console.log('Cancel')
        },
      })
    },
    customRow(record, index) {
      return {
        on: {
          click: (event) => {
            // console.log('record', record)
            // this.$refs.datadetail.open(record)
            // this.dropdownVisibility = true
          },
          contextmenu: (event) => {
            event.preventDefault()
            // this.dropdownVisibility = true
            // console.log('this.dropdownVisibility', this.dropdownVisibility)
          },
        },
      }
    },
    selectDate(date, dateString) {
      if (date === null) {
        this.tgl1 = moment().startOf('month').format('YYYY-MM-DD')
        this.tgl2 = moment().endOf('month').format('YYYY-MM-DD')
      } else {
        this.tgl1 = date.startOf('month').format('YYYY-MM-DD')
        this.tgl2 = date.endOf('month').format('YYYY-MM-DD')
      }
      this.getAllData()
    },
    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm()
      this.searchText = selectedKeys[0]
      this.searchedColumn = dataIndex
    },

    handleReset(clearFilters) {
      clearFilters()
      this.searchText = ''
    },
    numberFormat(value) {
      return lou.curency(value)
    },
    dynamicColor(id) {
      return lou.dynamicColors(id)
    },
  },
}
</script>

<style></style>
